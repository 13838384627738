<template>
  <div class="home">
    <v-layout row wrap>
      <v-flex xs12 md12 lg12 class="mb-5">
        <h1 class="f-alfa primary--text"><router-link to="/">Dashboard</router-link> / Create Case</h1>
      </v-flex>
      <v-flex xs12 md6 class="pa-5">
        <p class="primary--text fw-bold">Booking ID <v-icon class="ml-2 green--text" v-if="bookingFound">mdi-check-circle-outline</v-icon></p>
        <v-text-field
          single-line
          outlined
          v-model="bookingId"
          @change="validateBookingId"
        ></v-text-field>
      </v-flex>

      <v-flex xs12 md6 class="pa-5">
        <p class="primary--text fw-bold">Yellow Case ID</p>
        <v-text-field
          single-line
          outlined
          v-model="yellowCaseId"
        ></v-text-field>
      </v-flex>
      <v-flex xs12 md6 lg4 class="pa-5">
        <p class="primary--text fw-bold">Customer Name</p>
        <v-text-field
          single-line
          outlined
          v-model="name"
        ></v-text-field>
      </v-flex>
      <v-flex xs12 md6 lg4 class="pa-5">
        <p class="primary--text fw-bold">Customer Phone</p>
        <v-text-field
          single-line
          outlined
          v-model="phone"
        ></v-text-field>
      </v-flex>
      <v-flex xs12 md6 lg4 class="pa-5">
        <p class="primary--text fw-bold">Customer Email</p>
        <v-text-field
          single-line
          outlined
          v-model="email"
        ></v-text-field>
      </v-flex>
      <v-flex xs12 md12 class="pa-5">
        <p class="primary--text fw-bold">Case Description</p>
        <v-textarea v-model="caseDetail" single-line outlined></v-textarea>
      </v-flex>
      <v-flex xs12 md12 class="pa-5 text-right">
        <v-btn @click="createCase" x-large class="primary black--text">Submit</v-btn>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import Axios from "axios";
import { BASE_URL } from "../../config";
export default {
  data() {
    return {
      name : "",
      email : "",
      phone : "",
      bookingId: "",
      yellowCaseId: "",
      caseDetail: "",
      bookingFound: false,
    };
  },
  computed() {},
  methods: {
    async validateBookingId() {
      if (this.bookingId != "") {
        const headers = {
          Authorization:
            "JWT eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2NTQ0ODMyYjliYWFmMDVkMzNhNDEwYTMiLCJjaGFubmVsIjoiQm9va2luZy5jb20iLCJjcmVhdGVkQXQiOiIyMDIzLTExLTAzVDA1OjIwOjQzLjg2NVoiLCJ1cGRhdGVkQXQiOiIyMDIzLTExLTAzVDA1OjIwOjQzLjg2NVoiLCJfX3YiOjAsImlhdCI6MTY5OTE3MDEzOX0.iS7s7eHzP_yTGD0WIFYEoFSo9HvAuVfhawHkRpv2bls",
        };
        let url = BASE_URL + "/booking/" + this.bookingId;
        let { data } = await Axios.get(url, { headers });
        
        if (data.length > 0) {
            this.bookingFound = true
            this.name = data[0].name
            this.email = data[0].email
            this.phone = data[0].mobile_number
        }else{
            this.bookingFound = false
            this.name = ""
            this.email = ""
            this.phone = ""
        }
      }else{
        this.bookingFound = false
        this.name = "",
        this.email = "",
        this.phone = ""
      }
    },
    async createCase(){
        let url = BASE_URL + "/support"
        let payload = {booking_id : this.bookingId,description : this.caseDetail,yellow_case_id :  this.yellowCaseId,name : this.name,email : this.email, phone : this.phone}
        let {data} = await Axios.post(url,payload)
        this.name = ""
        this.email = ""
        this.phone = ""
        this.yellowCaseId = ""
        this.caseDetail = ""
        this.bookingFound = false
        this.bookingId = ""
        this.$router.push('/support/cases')
    }
  },
};
</script>